import { CARE_TYPES_OBJECT_TITLES } from '@sly/core/constants/careTypes';
import { userIs } from '@common/helpers/role';
import { titleize } from '@common/helpers/strings';
import { getStateAbbr } from '@common/helpers/url';
import { User } from '@sly/core/types';

export const AL_LEFT_LIST = [
  {
    title: 'Read our Definitive Guide for Assisted Living',
    to: '/assisted-living'
  }
];

export const AL_RIGHT_LIST = [
  {
    title: 'San Jose, CA',
    to: '/assisted-living/california/san-jose'
  },
  {
    title: 'Los Angeles, CA',
    to: '/assisted-living/california/los-angeles'
  },
  {
    title: 'Phoenix, AZ',
    to: '/assisted-living/arizona/phoenix'
  },
  {
    title: 'Dallas, TX',
    to: '/assisted-living/texas/dallas'
  },
  {
    title: 'Houston, TX',
    to: '/assisted-living/texas/houston'
  },
  {
    title: 'Atlanta, GA',
    to: '/assisted-living/georgia/atlanta'
  },
  {
    title: 'Chicago, IL',
    to: '/assisted-living/illinois/chicago'
  },
  {
    title: 'New York, NY',
    to: '/assisted-living/new-york/new-york'
  },
  {
    title: 'Washington, D.C.',
    to: '/assisted-living/district-of-columbia/washington'
  },
  {
    title: 'Denver, CO',
    to: '/assisted-living/colorado/denver'
  }
];

export const CCRC_LEFT_LIST = [
  {
    title:
      'Read our Definitive Guide for Continuing Care Retirement Communities',
    to: '/continuing-care-retirement-community'
  }
];

export const CCRC_RIGHT_LIST = [
  {
    title: 'San Jose, CA',
    to: '/continuing-care-retirement-community/california/san-jose'
  },
  {
    title: 'Los Angeles, CA',
    to: '/continuing-care-retirement-community/california/los-angeles'
  },
  {
    title: 'Phoenix, AZ',
    to: '/continuing-care-retirement-community/arizona/phoenix'
  },
  {
    title: 'Dallas, TX',
    to: '/continuing-care-retirement-community/texas/dallas'
  },
  {
    title: 'Houston, TX',
    to: '/continuing-care-retirement-community/texas/houston'
  },
  {
    title: 'Atlanta, GA',
    to: '/continuing-care-retirement-community/georgia/atlanta'
  },
  {
    title: 'Chicago, IL',
    to: '/continuing-care-retirement-community/illinois/chicago'
  },
  {
    title: 'New York, NY',
    to: '/continuing-care-retirement-community/new-york/new-york'
  },
  {
    title: 'Washington, D.C.',
    to: '/continuing-care-retirement-community/district-of-columbia/washington'
  },
  {
    title: 'Denver, CO',
    to: '/continuing-care-retirement-community/colorado/denver'
  }
];

export const IL_LEFT_LIST = [
  {
    title: 'Read our Definitive Guide for Independent Living',
    to: '/independent-living'
  }
];

export const IL_RIGHT_LIST = [
  {
    title: 'San Jose, CA',
    to: '/independent-living/california/san-jose'
  },
  {
    title: 'Los Angeles, CA',
    to: '/independent-living/california/los-angeles'
  },
  {
    title: 'Phoenix, AZ',
    to: '/independent-living/arizona/phoenix'
  },
  {
    title: 'Dallas, TX',
    to: '/independent-living/texas/dallas'
  },
  {
    title: 'Houston, TX',
    to: '/independent-living/texas/houston'
  },
  {
    title: 'Atlanta, GA',
    to: '/independent-living/georgia/atlanta'
  },
  {
    title: 'Chicago, IL',
    to: '/independent-living/illinois/chicago'
  },
  {
    title: 'New York, NY',
    to: '/independent-living/new-york/new-york'
  },
  {
    title: 'Washington, D.C.',
    to: '/independent-living/district-of-columbia/washington'
  },
  {
    title: 'Denver, CO',
    to: '/independent-living/colorado/denver'
  }
];

export const MC_LEFT_LIST = [
  {
    title: 'Read our Definitive Guide for Memory Care',
    to: '/memory-care'
  }
];

export const MC_RIGHT_LIST = [
  {
    title: 'San Jose, CA',
    to: '/memory-care/california/san-jose'
  },
  {
    title: 'Los Angeles, CA',
    to: '/memory-care/california/los-angeles'
  },
  {
    title: 'Phoenix, AZ',
    to: '/memory-care/arizona/phoenix'
  },
  {
    title: 'Dallas, TX',
    to: '/memory-care/texas/dallas'
  },
  {
    title: 'Houston, TX',
    to: '/memory-care/texas/houston'
  },
  {
    title: 'Atlanta, GA',
    to: '/memory-care/georgia/atlanta'
  },
  {
    title: 'Chicago, IL',
    to: '/memory-care/illinois/chicago'
  },
  {
    title: 'New York, NY',
    to: '/memory-care/new-york/new-york'
  },
  {
    title: 'Washington, D.C.',
    to: '/memory-care/district-of-columbia/washington'
  },
  {
    title: 'Denver, CO',
    to: '/memory-care/colorado/denver'
  }
];

export const SNF_LEFT_LIST = [
  {
    title: 'Read our Definitive Guide for Nursing Homes',
    to: '/skilled-nursing-facility'
  }
];

export const SNF_RIGHT_LIST = [
  {
    title: 'San Jose, CA',
    to: '/skilled-nursing-facility/california/san-jose'
  },
  {
    title: 'Los Angeles, CA',
    to: '/skilled-nursing-facility/california/los-angeles'
  },
  {
    title: 'Phoenix, AZ',
    to: '/skilled-nursing-facility/arizona/phoenix'
  },
  {
    title: 'Dallas, TX',
    to: '/skilled-nursing-facility/texas/dallas'
  },
  {
    title: 'Houston, TX',
    to: '/skilled-nursing-facility/texas/houston'
  },
  {
    title: 'Atlanta, GA',
    to: '/skilled-nursing-facility/georgia/atlanta'
  },
  {
    title: 'Chicago, IL',
    to: '/skilled-nursing-facility/illinois/chicago'
  },
  {
    title: 'New York, NY',
    to: '/skilled-nursing-facility/new-york/new-york'
  },
  {
    title: 'Washington, D.C.',
    to: '/skilled-nursing-facility/district-of-columbia/washington'
  },
  {
    title: 'Denver, CO',
    to: '/skilled-nursing-facility/colorado/denver'
  }
];

export const RESOURCES_LEFT_LIST = [
  // { title: HEADER_PHONE_NUMBER, to: HEADER_PHONE_NUMBER_LINK, chevron: false },
  { title: 'Ask a question', to: '/contact-us', chevron: false }
];

export const RESOURCES_RIGHT_LIST = [
  {
    title: 'Senior living resource center',
    to: '/resource-center'
  },
  {
    title: 'Seniorly pricing trends',
    to: '/senior-living-pricing-trends'
  },
  {
    title: "Veterans' benefits and assisted living",
    to: '/veterans-benefit-assisted-living'
  },
  {
    title: 'How Seniorly works',
    to: '/how-it-works'
  }
];

export const COMPANY_LEFT_LIST = [{ title: 'Learn more', to: '/about' }];

export const COMPANY_RIGHT_LIST = [
  {
    title: 'About',
    to: '/about'
  },
  {
    title: 'Contact',
    to: '/contact-us'
  },
  {
    title: 'Business Partners',
    to: '/partners/business'
  },
  {
    title: 'Press',
    to: '/press'
  },
  {
    title: 'Careers',
    to: '/career'
  }
];

export const indexMap = {
  [CARE_TYPES_OBJECT_TITLES.assistedLiving]: 0,
  [CARE_TYPES_OBJECT_TITLES.memoryCare]: 1,
  [CARE_TYPES_OBJECT_TITLES.snf]: 2,
  [CARE_TYPES_OBJECT_TITLES.independentLiving]: 3,
  [CARE_TYPES_OBJECT_TITLES.ccrc]: 4
};

export const lowerMenuItems = [
  {
    name: CARE_TYPES_OBJECT_TITLES.assistedLiving,
    leftTitle: 'About Assisted Living',
    rightTitle: 'Find Assisted Living Options Near You',
    leftLinks: AL_LEFT_LIST,
    rightLinks: AL_RIGHT_LIST,
    description:
      'Assisted living communities help older adults with activities of daily life like getting dressed, grooming, and medication management as well as taking care of laundry, cooking, and housekeeping.',
    eventProps: { location: 'header', text: 'Assisted Living' }
  },
  {
    name: CARE_TYPES_OBJECT_TITLES.memoryCare,
    leftTitle: 'About Memory Care',
    rightTitle: 'Find Memory Care Options Near You',
    leftLinks: MC_LEFT_LIST,
    rightLinks: MC_RIGHT_LIST,
    description:
      "Memory care communities or wings keep older adults with Alzheimer's/dementia safe from wandering while also offering specialized care to help relieve anxiety and slow cognitive decline.",
    eventProps: { location: 'header', text: 'Memory Care' }
  },
  {
    name: CARE_TYPES_OBJECT_TITLES.snf,
    leftTitle: 'About Nursing Homes',
    rightTitle: 'Find Nursing Homes Options Near You',
    leftLinks: SNF_LEFT_LIST,
    rightLinks: SNF_RIGHT_LIST,
    description:
      'Nursing homes, also known as skilled nursing facilities, provide short-term 24-hour medical care. Nursing homes help older adults recover from illness or injury with medical care, physical therapy, and other rehabilitative services.',
    eventProps: {
      location: 'header',
      text: 'Skilled Nursing Facilities'
    }
  },
  {
    name: CARE_TYPES_OBJECT_TITLES.independentLiving,
    leftTitle: 'About Independent Living',
    rightTitle: 'Find Independent Living Options Near You',
    leftLinks: IL_LEFT_LIST,
    rightLinks: IL_RIGHT_LIST,
    description:
      'Independent living communities offer a social, convenient, and stress-free environment for active and healthy older adults to live on their own terms.',
    eventProps: {
      location: 'header',
      text: 'Independent Living'
    }
  },
  {
    name: CARE_TYPES_OBJECT_TITLES.ccrc,
    leftTitle: 'About Continuing Care Retirement Communities',
    rightTitle: 'Find Continuing Care Retirement Communities Options Near You',
    leftLinks: CCRC_LEFT_LIST,
    rightLinks: CCRC_RIGHT_LIST,
    description:
      'Continuing Care Retirement Communities also known as CCRCs, offer comprehensive and flexible care services that allow residents to age in pace as their care needs change. Requires a buy-in fee.',
    eventProps: {
      location: 'header',
      text: 'Continuing Care Retirement Communities'
    }
  },
  {
    name: 'Resources',
    leftTitle: 'Talk with a Local Advisor',
    rightTitle: 'Helpful Links',
    rightLinks: RESOURCES_RIGHT_LIST,
    leftLinks: RESOURCES_LEFT_LIST,
    description:
      'Find definitions for common senior living terms, get answers to your questions about different types of care and communities, and learn how Seniorly is compensated for our free-for-you services.',

    eventProps: { location: 'header', text: 'Resources' }
  },
  {
    name: 'Company',
    leftTitle: 'About Seniorly',
    rightTitle: 'Learn More About Seniorly',
    leftLinks: COMPANY_LEFT_LIST,
    rightLinks: COMPANY_RIGHT_LIST,
    description:
      "Learn about Seniorly's history, mission, leadership, and how to get in touch with press and media inquiries. And don't forget to take a look at our open positions.",
    leftLinkText: 'Learn more',
    eventProps: {
      location: 'header',
      text: 'Company'
    }
  }
];

export const loggedInMenuItems = ({
  user,
  logIn,
  logOut,
  menuItems
}: {
  user: User;
  logIn: () => void;
  logOut: () => void;
  menuItems: Array<{
    label: string;
    Icon: any;
    href: string;
    role: number;
  }>;
}) => {
  const items = [];

  if (user) {
    menuItems
      .filter(mi => userIs(user, mi.role))
      .forEach(mi =>
        items.push({
          name: mi.label,
          to: mi.href,
          section: 2,
          Icon: mi.Icon,
          eventProps: { location: 'dropdown menu', text: mi.label }
        })
      );
    items.push({
      name: 'Log out',
      section: 4,
      color: 'text-viridian-base',
      onClick: logOut,
      eventProps: { location: 'mobile menu', text: 'Log out' },
      'data-tp-id': 'logout'
    });
  } else {
    items.push({
      name: 'Sign In',
      section: 4,
      onClick: logIn,
      eventProps: {
        cta: 'Launch Login',
        location: 'mobile menu',
        text: 'Log in'
      }
    });
  }

  return items;
};

export const parseLinks = (links: Array<{ to: string; title: string }>) => {
  // Loop through link and remove care type
  return links.slice(0, 10).map(({ to }) => {
    const arr = to?.split('/');
    const newTitle = titleize(arr[3]) + ', ' + getStateAbbr(titleize(arr[2]));
    return { to, title: newTitle };
  });
};
