/** @jsxImportSource react */
import {
  indexMap,
  loggedInMenuItems,
  lowerMenuItems,
} from '@common/helpers/header';
import { titleize } from '@common/helpers/strings';
import { generateSearchUrl, getStateAbbr } from '@common/helpers/url';
import { usePrefetch } from '@react/services/api';
import { useAuth } from '@react/services/auth';
import { eventNames, useEvents } from '@react/services/events';
import {
  AGENT,
  CUSTOMER,
  LOGGINGIN,
  PROVIDER,
  REGISTER,
} from '@sly/core/constants/auth';
import { CARE_TYPES_OBJECT_TITLES } from '@sly/core/constants/careTypes';
import AuthWizard from '@sly/frontend/react/wizards/auth';
import cloneDeep from 'lodash/cloneDeep';
import { useRouter } from 'next/router';
import { func, object, oneOf, shape } from 'proptypes';
import { memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { menuItems } from './DashboardMenu';
import Header from './Header';

const parseLinks = (links) => {
  // Loop through link and remove care type
  return links.slice(0, 10).map(({ to }) => {
    const arr = to?.split('/');
    const newTitle = titleize(arr[3]) + ', ' + getStateAbbr(titleize(arr[2]));
    return { to, title: newTitle };
  });
};

const HeaderContainer = ({
  registerContext = {},
  isPlus = false,
  showHeaderAgentPhNo = false,
  showHeaderPhNo = false,
}) => {
  const history = useHistory();
  const {
    user,
    logoutUser,
    shouldAuthenticate,
    authenticatedCancel,
    authenticatedSuccess,
    ensureAuthenticated,
    authenticating,
    provider,
  } = useAuth();

  const {
    query: { city, state },
  } = useRouter();

  const { requestInfo: geoGuidesAll } = usePrefetch(
    'getGeoGuidesAll',
    {
      city,
      state,
    },
    { shouldBail: !(city && state) }
  );

  const alSeoLinks = geoGuidesAll?.normalized?.al?.info?.seoLinks || [];
  const mcSeoLinks = geoGuidesAll?.normalized?.mc?.info?.seoLinks || [];
  const snfSeoLinks = geoGuidesAll?.normalized?.snf?.info?.seoLinks || [];
  const ccrcSeoLinks = geoGuidesAll?.normalized?.ccrc?.info?.seoLinks || [];
  const ilSeoLinks = geoGuidesAll?.normalized?.il?.info?.seoLinks || [];

  const { events } = useEvents();

  // If we want to show nearby based on user's city and state
  // const guideRequest = {
  //   'assisted-living': setAlLinks,
  //   'memory-care': setMcLinks,
  //   'skilled-nursing-facility': setSnfLinks,
  // };

  // Get seo links close to the user
  // useEffect(() => {
  //   if (
  //     user?.uuidAux?.uuidInfo?.locationInfo?.city &&
  //     user?.uuidAux?.uuidInfo?.locationInfo?.state
  //   ) {
  //     Object.keys(guideRequest).forEach((careType) => {
  //       getGeoGuide(
  //         {
  //           city: user?.uuidAux?.uuidInfo?.locationInfo?.city,
  //           state: user?.uuidAux?.uuidInfo?.locationInfo?.state,
  //           toc: careType,
  //         },
  //         {
  //           encode: false,
  //         }
  //       )
  //         .then((res) => normalizeResponse(res.body))
  //         .then(([guide]) =>
  //           // Slice because we only want to show 10
  //           guideRequest[careType](guide?.guideContent?.seoLinks?.slice(0, 10))
  //         )
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     });
  //   }
  // }, [
  //   user?.uuidAux?.uuidInfo?.locationInfo?.city,
  //   user?.uuidAux?.uuidInfo?.locationInfo?.state,
  // ]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(() => {
    setDropdownOpen(!isDropdownOpen);
    events.track(eventNames.ButtonClicked, {
      text: user?.name,
      cta: 'Toggle menu',
      location: 'header',
    });
  }, [user, isDropdownOpen]);

  const handleCurrentLocation = useCallback((addresses) => {
    if (addresses.length) {
      const path = `${generateSearchUrl(['Senior Living'], addresses[0])}`; // ?geo=${latitude},${longitude},10`;
      history.push(path);
    }
  }, []);

  // fix me
  const isInternationalPage =
    // isInternationalPath(pathname) ||
    false;

  const signUpWithContext = () =>
    ensureAuthenticated(
      registerContext?.callback,
      registerContext?.role,
      registerContext?.action,
      registerContext?.data
    );

  const logInWithContext = () => ensureAuthenticated();

  const logOut = () => {
    logoutUser();
    toggleDropdown();
  };

  const loggedInMenu = loggedInMenuItems({
    user,
    logIn: logInWithContext,
    signUp: signUpWithContext,
    logOut,
    menuItems,
  });

  // Assign nearby links if we have them from the geo guides
  const lowerMenuItemsWithLinks = cloneDeep(lowerMenuItems);
  if (alSeoLinks?.length > 0) {
    lowerMenuItemsWithLinks[
      indexMap[CARE_TYPES_OBJECT_TITLES.assistedLiving]
    ].rightLinks = parseLinks(
      alSeoLinks,
      CARE_TYPES_OBJECT_TITLES.assistedLiving
    );
  }
  if (mcSeoLinks?.length > 0) {
    lowerMenuItemsWithLinks[
      indexMap[CARE_TYPES_OBJECT_TITLES.memoryCare]
    ].rightLinks = parseLinks(
      mcSeoLinks,
      CARE_TYPES_OBJECT_TITLES.assistedLiving
    );
  }
  if (snfSeoLinks?.length > 0) {
    lowerMenuItemsWithLinks[indexMap[CARE_TYPES_OBJECT_TITLES.snf]].rightLinks =
      parseLinks(snfSeoLinks, CARE_TYPES_OBJECT_TITLES.assistedLiving);
  }

  if (ccrcSeoLinks?.length > 0) {
    lowerMenuItemsWithLinks[
      indexMap[CARE_TYPES_OBJECT_TITLES.ccrc]
    ].rightLinks = parseLinks(ccrcSeoLinks, CARE_TYPES_OBJECT_TITLES.ccrc);
  }

  if (ilSeoLinks?.length > 0) {
    lowerMenuItemsWithLinks[
      indexMap[CARE_TYPES_OBJECT_TITLES.independentLiving]
    ].rightLinks = parseLinks(
      ilSeoLinks,
      CARE_TYPES_OBJECT_TITLES.independentLiving
    );
  }

  return (
    <>
      <Header
        menuOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        loggedInMenuItems={loggedInMenu}
        lowerMenuItems={lowerMenuItemsWithLinks}
        onCurrentLocation={handleCurrentLocation}
        showLogin={!isInternationalPage}
        user={user}
        logIn={logInWithContext}
        signUp={signUpWithContext}
        isPlus={isPlus}
        authenticating={authenticating}
        showHeaderAgentPhNo={showHeaderAgentPhNo}
        showHeaderPhNo={showHeaderPhNo}
      />
      {shouldAuthenticate && (
        <AuthWizard
          authenticatedCancel={authenticatedCancel}
          authenticatedSuccess={authenticatedSuccess}
          isOpen={shouldAuthenticate}
          onClose={authenticatedCancel}
          additionalModalProps={{ isProvider: provider }}
        />
      )}
    </>
  );
};

HeaderContainer.propTypes = {
  registerContext: shape({
    role: oneOf([CUSTOMER, PROVIDER, AGENT]),
    action: oneOf([REGISTER, LOGGINGIN]),
    data: object,
    callback: func,
  }),
};

export default memo(HeaderContainer);
